import React, { useEffect, Component } from 'react'
import { connect } from 'react-redux'
import { PayerAuthStep } from '../actions/cybersourceThreeDs'
import { paymentEnrollmentCybersourceThreeDsRedux } from '../actions/services'

const PayerAuthenticationDeviceDataCollection = ({ payerAuthenticationServiceData, cardToken, originUrl, cardData, callback }) => {
  const { accessToken, deviceDataCollectionUrl, referenceId } = payerAuthenticationServiceData
  window.addEventListener('message', event => {
    if (event.origin === originUrl) {
      callback(cardToken, { ...cardData, referenceId })
    }
  })
  useEffect(() => {
    const formElement = document.getElementById('cardinal_collection_form')
    if (formElement) {
      formElement.submit()
    }
  })
  return (
    <div>
      <iframe
        id="cardinal_collection_iframe"
        style={{ display: 'none' }}
        name="collectionIframe"
        height="1"
        width="1"
        title="Device data collection"
      />
      <form id="cardinal_collection_form" target="collectionIframe" method="POST" action={deviceDataCollectionUrl}>
        <input id="cardinal_collection_form_input" type="hidden" name="JWT" value={accessToken} />
      </form>
    </div>
  )
}

const PayerAuthenticationEnrollment = ({ stepUpUrl, accessToken, cardToken }) => {
  useEffect(() => {
    const formElement = document.getElementById('step_up_form')
    if (formElement) {
      formElement.submit()
    }
  })
  return (
    <div>
      <form id="step_up_form" method="POST" action={stepUpUrl}>
        <input type="hidden" name="JWT" value={accessToken} />
        <input type="hidden" name="MD" value={cardToken} />
      </form>
    </div>
  )
}

class CheckoutCybersourceThreeDsPayment extends Component {
  constructor(props) {
    super(props)
    this.step = props.step
    if (this.step === PayerAuthStep.SETUP) {
      this.payerAuthenticationSetupResult = props.payerAuthenticationSetupResult.payerAuthenticationServiceData
      this.cardToken = props.payerAuthenticationSetupResult.token
      this.cardData = props.payerAuthenticationSetupResult.cardData
      this.originUrl = props.payerAuthenticationSetupResult.url
    }
    this.payerAuthenticationCheckEnrollment = this.props.payerAuthenticationCheckEnrollment
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.step === PayerAuthStep.SETUP && nextProps.step === PayerAuthStep.ENROLLMENT) {
      this.step = nextProps.step
      const { stepUpUrl, accessToken } = nextProps.payerAuthenticationEnrollmentResult
      this.stepUpUrl = stepUpUrl
      this.accessToken = accessToken
    }
  }

  render() {
    if (this.step === PayerAuthStep.SETUP) {
      return (
        <PayerAuthenticationDeviceDataCollection
          payerAuthenticationServiceData={this.payerAuthenticationSetupResult}
          cardToken={this.cardToken}
          originUrl={this.originUrl}
          cardData={this.cardData}
          callback={this.payerAuthenticationCheckEnrollment}
        />
      )
    }
    if (this.step === PayerAuthStep.ENROLLMENT) {
      return <PayerAuthenticationEnrollment stepUpUrl={this.stepUpUrl} accessToken={this.accessToken} cardToken={this.cardToken} />
    }
    return null
  }
}

const mapStateToProps = state => ({
  payerAuthenticationSetupResult: state.cybersourceThreeDsPayment.payerAuthenticationSetupResult,
  payerAuthenticationEnrollmentResult: state.cybersourceThreeDsPayment.payerAuthenticationEnrollmentResult,
  step: state.cybersourceThreeDsPayment.step,
})

const mapDispatchToProps = dispatch => ({
  payerAuthenticationCheckEnrollment: (cardToken, cardData) => {
    dispatch(paymentEnrollmentCybersourceThreeDsRedux(cardToken, cardData))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutCybersourceThreeDsPayment)

const styles = {
  inline: {
    display: 'inline-block',
  },
  floatLeft: {
    float: 'left',
    clear: 'left',
  },
  floatRight: {
    float: 'right',
    clear: 'right',
  },
  tinyFont: {
    fontSize: '11px',
    fontStyle: 'italic',
  },
  doublePadLeft: {
    marginLeft: '4px',
  },
  checkoutWrapper: {
    borderBottom: '1px solid white',
    height: '100%',
    paddingTop: '120px',
    width: '100%',
  },
  formWrapper: {
    width: '100%',
    paddingBottom: '13px',
  },
  sectionWrapper: {
    width: '89.33333%',
    margin: '0 auto',
    paddingTop: '10px',
  },
  labelWrapper: {
    textAlign: 'center',
    width: '100%',
    height: '19px',
    margin: '0px auto 30px',
    paddingTop: '1px',
  },
  sectionLabel: {
    fontSize: '14px',
  },
  infoForm: {
    borderRadius: '8px',
    boxShadow: '0px 0px 2px -1px rgba(0,0,0,0.8)',
  },
  formLine: {
    width: '100%',
    height: '46px',
    display: 'flex',
  },
  formBox: {
    height: '100%',
    flex: '1',
  },
  firstNameInput: {
    padding: '0px 2% 0px 8.35392%',
    borderRadius: '8px 0 0 0',
  },
  lastNameInput: {
    padding: '0px 2% 0px 8.35392%',
    borderRadius: '0 8px 0 0',
  },
  singleFieldInput: {
    padding: '0 3.69880% 0 4.17910%',
  },
  topBorderRadius: {
    borderRadius: '8px 8px 0 0',
  },
  bottomBorderRadius: {
    borderRadius: '0 0 8px 8px',
  },
  formLineSeperator: {
    borderStyle: 'solid',
    borderWidth: '0 0 1px',
    color: '#e7e7e7',
    margin: '0',
  },
  thirdBox: {
    paddingLeft: '12.532%',
  },
  addButton: {
    display: 'inline-block',
    float: 'right',
    fontSize: '14px',
    padding: '3px 7px',
    height: '25px',
    marginTop: '-2px',
    width: '23.5%',
    maxWidth: '70px',
    outline: 'none',
    cursor: 'pointer',
  },
  summaryWrapper: {
    width: '89.33333%',
    margin: '0 auto',
  },
  summaryLine: {
    width: '100%',
    padding: '11px 4.17910% 11px 3.28358%',
    overflow: 'auto',
  },
  lineIcon: {
    fontSize: '23px',
    display: 'inline-block',
    float: 'left',
  },
  summaryText: {
    display: 'inline-block',
    fontSize: '14px',
    float: 'left',
    lineHeight: '23px',
    paddingLeft: '10px',
    maxWidth: '85%',
  },
  adjustedText: {
    maxWidth: '65%',
  },
  priceBreakdown: {
    width: '100%',
    padding: '12px 4.17910% 16px 4.47761%',
    overflow: 'auto',
  },
  breakdown: {
    height: '21px',
    display: 'inline-block',
    fontSize: '14px',
    lineHeight: '1.5',
  },
  lastBreakdown: {
    height: '29px',
    display: 'inline-block',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '1.5',
    paddingTop: '8px',
  },
  policySection: {
    width: '100%',
    height: '50px',
    border: '2px solid #fff',
    padding: '14px 4.17910% 5px 4.47761%',
    '@media (max-width: 320px)': {
      padding: '16px 3.1791% 5px 3.1791%',
    },
  },
  policy: {
    position: 'relative',
    width: '100%',
    height: '20px',
    marginBottom: '14px',
  },
  policyLine: {
    display: 'inline-block',
    float: 'left',
    height: '19px',
    fontSize: '13px',
    cursor: 'pointer',
    maxWidth: '240px',
  },
  infoIcon: {
    fontSize: '23px',
    display: 'inline-block',
    float: 'right',
    margin: '-2px 2px 0 0',
    cursor: 'pointer',
  },
  footerText: {
    width: '100%',
    padding: '7px 8.35828% 10px',
    fontWeight: '300',
    fontSize: '12px',
    lineHeight: '1.8',
    textAlign: 'left',
  },
  link: {
    textDecoration: 'none',
    display: 'inline-block',
    cursor: 'pointer',
  },
  buttonWrapper: {
    paddingBottom: '40px',
  },
  submitButton: {
    width: '92.26666%',
    height: '50px',
    borderRadius: '4px',
    textAlign: 'center',
    margin: '0 4%',
    padding: '15px 0',
    fontWeight: '400',
    fontSize: '14px',
    letterSpacing: '1px',
    color: 'white',
    cursor: 'pointer',
  },
  shiftUp: {
    marginTop: '-2px',
  },
  activeLine: {
    cursor: 'pointer',
    ':active': {
      backgroundColor: 'rgba(199, 226, 241, 1)',
    },
  },
  switch: {
    float: 'right',
  },
  summaryHeader: {
    fontSize: 16,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  paylinkFooter: {
    borderTop: '1px solid #eee',
    color: '#aaa',
    fontSize: '12px',
    padding: '20px 0 20px',
    textAlign: 'center',
  },
  stripeIframe: {
    color: '#252525',
    fontFamily: 'Avenir, Roboto, sans-serif',
    fontSizeBase: '14px',
    fontSize: '15px',
    lineHeight: '46px',
    padding: '4.5% 2% 4.5% 4.5%',
  },
}

export default styles

import Radium from 'radium'
import React from 'react'
import styles from './styles'

// Switch component requires certain fort awesome fonts
const Switch = ({ isChecked, onClick, style, checkedColor, notCheckedColor }) => (
  <div style={[styles.switchWrapper, style]} onClick={onClick}>
    <i
      style={[styles.switchBackgroundIcon, { color: isChecked ? checkedColor : notCheckedColor }]}
      className={`fa fa-${isChecked ? 'toggle-on' : 'toggle-off'}`}
      aria-hidden="true"
    />
    <div style={[styles.switchCircle, { borderColor: notCheckedColor }, isChecked ? styles.circleRight : styles.circleLeft]} />
  </div>
)

Switch.propTypes = {
  isChecked: React.PropTypes.bool.isRequired,
  onClick: React.PropTypes.func.isRequired,
  checkedColor: React.PropTypes.string,
  notCheckedColor: React.PropTypes.string,
  style: React.PropTypes.objectOf(React.PropTypes.string),
}

Switch.defaultProps = {
  checkedColor: 'rgba(50, 70, 84, 1.00)',
  notCheckedColor: 'rgba(208, 208, 208, 1.00)',
  style: {},
}

export default Radium(Switch)

import _ from 'lodash'
import Radium from 'radium'
import React from 'react'
import Button from '../../../../lib/Buttons/Button'

const styles = {
  bannerWrapper: {
    position: 'fixed',
    top: '0',
    left: '0',
    height: '100px',
    margin: '0 auto',
    width: '100%',
    minWidth: '296px',
    zIndex: '20',
    padding: '7px',
    fontSize: '30px',
    textAlign: 'center',
    color: 'white',
    lineHeight: '100px',
    backgroundColor: 'rgba(50, 70, 84, 1.00)',
  },
}

const Banner = ({ name, logo, onBackClick, colorSummaryBar, fontsColorSummaryBar }) => {
  const content = logo ? <img src={logo} style={{ height: '100%' }} /> : name
  return <div style={[styles.bannerWrapper, { color: fontsColorSummaryBar, backgroundColor: colorSummaryBar }]}>{content}</div>
}

Banner.propTypes = {
  colorSummaryBar: React.PropTypes.string,
  fontsColorSummaryBar: React.PropTypes.string,
}

Banner.defaultProps = {
  colorSummaryBar: 'rgba(185, 243, 243, 1.00)',
  fontsColorSummaryBar: 'white',
}

export default Radium(Banner)

const styles = {
  modalWidth: {
    width: '76vw',
    minWidth: '250px',
    maxWidth: '655px',
  },
  staticModalHeight: {
    height: '92vh',
  },
  headerLine: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '5px',
    fontSize: '14px',
  },
  smallFont: {
    fontSize: '14px',
  },
  seperator: {
    width: '100%',
    height: '0px',
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
  },
  tabWrapper: {
    paddingTop: '12px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  tab: {
    flex: '1',
    fontSize: '16px',
    textAlign: 'center',
    paddingTop: '16px',
    borderBottomWidth: '4px',
    borderBottomStyle: 'solid',
    borderBottomColor: 'transparent',
    cursor: 'pointer',
  },
  circle: {
    display: 'inline-block',
    borderRadius: '50%',
    borderWidth: '1px',
    borderStyle: 'solid',
    marginLeft: '10px',
    textAlign: 'center',
    height: '20px',
    width: '20px',
    fontSize: '15px',
    lineHeight: '13px',
    paddingTop: '1px',
    userSelect: 'none',
  },
  textAreaWrapper: {
    padding: '15px 10px',
    height: '114px',
  },
  textArea: {
    outline: 'none',
    border: 'none',
    resize: 'none',
    height: '100%',
    width: '100%',
    fontSize: '14px',
    backgroundColor: 'transparent',
  },
  policyHeader: {
    width: '100%',
    height: '50px',
    padding: '15px 4.26666% 13px 6.66666%',
    borderRadius: '7px 7px 0 0',
  },
  headerText: {
    display: 'inline-block',
    float: 'left',
    width: '169px',
    height: '22px',
    fontSize: '16px',
  },
  headerX: {
    display: 'inline-block',
    width: '18px',
    height: '18px',
    float: 'right',
    marginTop: '-9px',
    cursor: 'pointer',
  },
  policyBody: {
    background: '#fff',
    fontSize: '14px',
    overflow: 'auto',
    padding: '19px 7.10227% 19px 7.10227%',
  },
}

export default styles

import { combineReducers } from 'redux'
import commonPaymentReducer from 'svr/component-lib/Widget/Payments/reducer'
import cybersourceThreeDsPaymentReducer from './cybersourcePaymentReducer'
import formFields from './formFieldsReducer'
import languageReducer from './languageReducer'
import paymentReducer from './paymentReducer'
import ui from './uiReducer'

export const rootReducer = combineReducers({
  // Constant state
  venueInfo: (state = {}) => state,
  billingInfo: (state = {}) => state,
  actualInfo: (state = {}) => state,
  widgetSettings: (state = {}) => state,
  app: (state = {}) => state,
  // Mutable state
  formFields,
  ui,
  languages: languageReducer,
  payment: paymentReducer, // should be deleted later
  commonPayment: commonPaymentReducer,
  cybersourceThreeDsPayment: cybersourceThreeDsPaymentReducer,
})

import _ from 'lodash'
import { createSelector } from 'reselect'

const languageStrings = state => state.languages.languageStrings
const getLanguageStringValue = (newValue, originalValue) => (!_.isNil(newValue) ? newValue : originalValue)

export const selectLanguageStrings = createSelector([languageStrings], languageStrings => ({
  textWidgetErrorsCardIncomplete: getLanguageStringValue(languageStrings.resWidgetErrorsCardIncomplete, 'Your card number is incomplete.'),
  textPaylinkPageServiceCharge: getLanguageStringValue(languageStrings.paylinkPageServiceCharge, 'Service charge'),

  textResWidgetGuestInformationHeader: getLanguageStringValue(languageStrings.resWidgetGuestInformationHeader, 'Your Information'),

  textCommonPaymentGeneralSelectATipLabel: getLanguageStringValue(languageStrings.commonPaymentGeneralSelectATipLabel, 'Select a tip'),
  textCommonPaymentGeneralSelectLabel: getLanguageStringValue(languageStrings.commonPaymentGeneralSelectLabel, 'Select'),
  textCommonPaymentGeneralTaxLabel: getLanguageStringValue(languageStrings.commonPaymentGeneralTaxLabel, 'Tax'),
  textWidgetErrorsCardInvalid: getLanguageStringValue(languageStrings.resWidgetErrorsCardInvalid, 'Your card number is invalid.'),

  textWidgetErrorsCardExpired: getLanguageStringValue(
    languageStrings.resWidgetErrorsCardExpired,
    "Your card's expiration year is in the past."
  ),

  textWidgetErrorsPhoneInvalid: getLanguageStringValue(
    languageStrings.resWidgetErrorsPhoneInvalid,
    'The phone number entered is invalid. Please enter a valid phone number to continue.'
  ),

  testCommonPaymentErrorIncompleteSecCode: getLanguageStringValue(
    languageStrings.commonPaymentErrorIncompleteSecCode,
    "Your card's security code is incomplete."
  ),
  testcommonPaymentErrorIncompletePostalCode: getLanguageStringValue(
    languageStrings.commonPaymentErrorIncompletePostalCode,
    'Your postal code is incomplete.'
  ),

  textCommonPaymentErrorUnableToProcess: getLanguageStringValue(
    languageStrings.commonPaymentErrorUnableToProcess,
    'Unable to process request. Please try again'
  ),
  textCommonPaymentErrorConnectionError: getLanguageStringValue(
    languageStrings.commonPaymentErrorConnectionError,
    'Could not connect to payment processor.'
  ),
  textCommonPaymentErrorProcessingError: getLanguageStringValue(
    languageStrings.commonPaymentErrorProcessingError,
    'We could not process your card.'
  ),
  textCommonPaymentErrorPaymentRejectedError: getLanguageStringValue(
    languageStrings.commonPaymentErrorPaymentRejectedError,
    'Payment attempt was rejected'
  ),
  textCommonPaymentErrorUnexpectedError: getLanguageStringValue(
    languageStrings.commonPaymentErrorUnexpectedError,
    'Unexpected error has occurred. Please refresh the page and try again.'
  ),
  textCommonPaymentErrorIncompleteCard: getLanguageStringValue(
    languageStrings.commonPaymentErrorIncompleteCard,
    'Your card number is incomplete.'
  ),
  textCommonPaymentErrorIncompleteSecCode: getLanguageStringValue(
    languageStrings.commonPaymentErrorIncompleteSecCode,
    "Your card's security code is incomplete."
  ),
  textCommonPaymentErrorIncompletePostalCode: getLanguageStringValue(
    languageStrings.commonPaymentErrorIncompletePostalCode,
    'Your postal code is incomplete.'
  ),
  textCommonPaymentErrorExpiredCard: getLanguageStringValue(
    languageStrings.commonPaymentErrorExpiredCard,
    "Your card's expiration year is in the past."
  ),

  textCreditCardZipcode: getLanguageStringValue(languageStrings.resCreditCardZipcodeLabel, 'Zipcode'),
  textcommonOk: getLanguageStringValue(languageStrings.commonOkText, 'Ok'),
  textPaylinkPageCreditCardDetailsHeader: getLanguageStringValue(
    languageStrings.paylinkPageCreditCardDetailsHeader,
    'Please add your credit card details'
  ),
  textPaylinkPageProcessingRequest: getLanguageStringValue(languageStrings.resPaylinkPageProcessingRequestText, 'Processing Request'),
  textPaylinkPageUnableToProcess: getLanguageStringValue(languageStrings.resPaylinkPageUnableToProcessText, 'Unable to process request'),
  textCommonAnd: getLanguageStringValue(languageStrings.commonAndText, ' and '),
  textCheckoutSubmitButton: getLanguageStringValue(languageStrings.resWidgetCheckoutSubmitButtonLabel, 'Submit'),
  textWidgetPoweredByLabel: getLanguageStringValue(languageStrings.resWidgetPoweredByLabel, 'powered by'),
  textWidgetPolicyDisclaimer: getLanguageStringValue(languageStrings.resWidgetPolicyDisclaimerText, 'By clicking below you agree to '),
  textFirstName: getLanguageStringValue(languageStrings.resWidgetFirstNameLabel, 'First Name'),
  textLastName: getLanguageStringValue(languageStrings.resWidgetLastNameLabel, 'Last Name'),
  textPaylinkPageCreditCardDetailsSubheader: getLanguageStringValue(
    languageStrings.paylinkPageCreditCardDetailsSubheader,
    'Credit Card Details'
  ),
  textCreditCardNumber: getLanguageStringValue(languageStrings.resCreditCardCardNumLabel, 'Credit Card Number'),
  textCvv: getLanguageStringValue(languageStrings.resCreditCardCvvLabel, 'cvv '),
  textWidgetEmailLabel: getLanguageStringValue(languageStrings.resWidgetEmailLabel, 'Email address'),
  textPaylinkPageContactInformation: getLanguageStringValue(
    languageStrings.paylinkPageContactInformationText,
    'If you have any questions please contact us at <venue_phone_number>'
  ),
  textCommonAtText: getLanguageStringValue(languageStrings.commonAtText, 'at'),
  textCommonGuestsLabel: getLanguageStringValue(languageStrings.commonGuestsLabel, 'guests'),
  textCommonGuestLabel: getLanguageStringValue(languageStrings.commonGuestLabel, 'guest'),
  textCustomPrivacyPolicyLinkLabel: getLanguageStringValue('Privacy Policy'),
  textCustomGdprPolicyLinkLabel: getLanguageStringValue(languageStrings.resWidgetCustomGdprPolicyLinkLabel, 'GDPR Policy'),
  textWidgetTermsOfServiceLinkLabel: getLanguageStringValue(languageStrings.resWidgetTermsOfServiceLinkLabel, 'Terms of Service'),
  textWidgetSummaryLabel: getLanguageStringValue(languageStrings.resWidgetSummaryLabel, 'Summary'),
  textCommonTax: getLanguageStringValue(languageStrings.commonTaxText, 'tax'),
  textWidgetOrderTotalLabel: getLanguageStringValue(languageStrings.resWidgetOrderTotalLabel, 'order total'),
  textWidgetTipLabel: getLanguageStringValue(languageStrings.resWidgetTipLabel, 'tip '),
  textWidgetUpgradesLabel: getLanguageStringValue(languageStrings.resWidgetUpgradesLabel, 'Upgrades'),
  textPaylinkPageSuccessHeader: getLanguageStringValue(languageStrings.paylinkPageSuccessHeader, 'Success!'),
  textPaylinkPageSuccessConfirmationText: getLanguageStringValue(
    languageStrings.paylinkPageSuccessConfirmationText,
    'Your payment has been received and you will receive a payment confirmation email at <guest_email>'
  ),
  textWidgetReservationNotesPlaceholder: getLanguageStringValue(languageStrings.resWidgetReservationNotesPlaceholder, 'Leave a note...'),
  textWidgetSaveButtonLabel: getLanguageStringValue(languageStrings.resWidgetSaveButtonLabel, 'Save'),
  textConfirmPageCancelButton: getLanguageStringValue(languageStrings.resConfirmPageCancelButton, 'Cancel'),
  textWidgetSearchButton: getLanguageStringValue(languageStrings.resWidgetSearchButton, 'Search'),
  textWidgetMarketingPolicyHeader: getLanguageStringValue(languageStrings.resWidgetMarketingPolicyHeader, 'Marketing Policy'),
  textWidgetCancellationPolicyHeader: getLanguageStringValue(languageStrings.resWidgetCancellationPolicyHeader, 'Cancellation Policy'),
  textWidgetTagLabelYourGuests: getLanguageStringValue(languageStrings.resWidgetTagLabelYourGuests, 'YOUR GUESTS'),
  textWidgetTagLabelYours: getLanguageStringValue(languageStrings.resWidgetTagLabelYours, 'YOURS'),
  textPaylinkPageExpiredLink: getLanguageStringValue(languageStrings.resPaylinkPageExpiredLinkText, 'This link is no longer valid'),
  textPaylinkPageOrderSubLabel: getLanguageStringValue(languageStrings.resPaylinkPageOrderSubLabel, 'order subtotal'),
  textPaylinkPageFollowUsLabel: getLanguageStringValue(languageStrings.resPaylinkPageFollowUsLabel, 'follow us on'),
  textPaylinkPageErrorText: getLanguageStringValue(languageStrings.resPaylinkPageErrorText, 'Error'),
  textPaylinkPageTryAgainText: getLanguageStringValue(languageStrings.resPaylinkPageTryAgainText, 'Try again'),
  textPaylinkPagePaymentErrorText: getLanguageStringValue(
    languageStrings.resPaylinkPagePaymentErrorText,
    'There was an error processing your payment.'
  ),
  textPaylinkPageCancellation: getLanguageStringValue(languageStrings.resPaylinkPageCancellationText, 'cancellation'),
  textPaylinkPageCcLabel: getLanguageStringValue(languageStrings.resPaylinkPageCcLabel, 'credit card'),
  textPaylinkPageCreditCardPolicy: getLanguageStringValue(languageStrings.resPaylinkPageCreditCardPolicy, 'Credit Card Policy'),
  textPaylinkPageAgreeTo: getLanguageStringValue(languageStrings.resPaylinkPageAgreeToText, 'I agree to the '),
  textCreditCardExpMonthLabel: getLanguageStringValue(languageStrings.resCreditCardExpMonthLabel, 'mm '),
  textCreditCardExpYearLabel: getLanguageStringValue(languageStrings.resCreditCardExpYearLabel, 'yyyy '),
  textCreditCardExpYearDefaultLabel: getLanguageStringValue(languageStrings.resCreditCardExpYearDefaultLabel, 'yy '),
  textPaylinkPagePolicy: getLanguageStringValue(languageStrings.resPaylinkPagePolicyText, 'policy'),
  textPaylinkPageCcSuccess: getLanguageStringValue(
    languageStrings.resPaylinkPageCcSuccessText,
    'Your credit card details have been received.'
  ),
  waitlistWidgetPrivacyPolicyLabel: getLanguageStringValue(languageStrings.waitlistWidgetPrivacyPolicyLabel, 'Privacy Policy'),
}))

/* global Stripe */
import { Map } from 'immutable'
import { CHANGE_COUNTRY_FLAG } from 'widget/dining/actions/ActionTypes'
import {
  CHANGE_PHONE_NUM,
  STRIPE_ERROR,
  VALIDATE_ALL_FIELDS,
  POST_CHECKOUT_FAILURE,
  TOGGLE_FIELD,
  CHANGE_FORM_FIELD,
  CHANGE_GRATUITY_PERCENTAGE,
} from '../actions/ActionTypes'

const formFields = (state = Map(), action) => {
  switch (action.type) {
    case CHANGE_FORM_FIELD: {
      return state.merge({
        [action.field]: action.changeTo,
        formErrors: state.get('formErrors').delete(action.field),
      })
    }
    case CHANGE_PHONE_NUM:
      return state.merge({
        phoneNumber: action.changeTo,
        phoneNumberLocale: action.selectedCountry,
        dialCode: action.dialCode,
        formErrors: state.get('formErrors').delete('phoneNumber'),
      })
    case CHANGE_COUNTRY_FLAG: {
      return state.merge({
        phoneNumberLocale: action.selectedCountry,
      })
    }
    case CHANGE_GRATUITY_PERCENTAGE:
      return state.merge({
        [action.field]: action.changeTo.target.value,
        formErrors: state.get('formErrors').delete(action.field),
      })
    case STRIPE_ERROR: {
      let formField
      if (action.stripeField === 'number') {
        formField = 'cardNum'
      } else if (action.stripeField === 'exp_month') {
        formField = 'cardMonthExp'
      } else if (action.stripeField === 'exp_year') {
        formField = 'cardYearExp'
      } else {
        formField = 'cardCvv'
      }
      return state.set(formField, true)
    }
    case VALIDATE_ALL_FIELDS: {
      return state.merge({
        formErrors: action.formErrors,
      })
    }
    case POST_CHECKOUT_FAILURE:
      return state.merge({
        checkoutStatus: 'failure',
        error: ((action.response || {}).errors || []).join(', '),
      })
    case TOGGLE_FIELD: {
      const fieldFlag = !state.get(action.field)
      return state.merge({
        [action.field]: fieldFlag,
        formErrors: state.get('formErrors').delete(action.field),
      })
    }
    default:
      return state
  }
}

export default formFields

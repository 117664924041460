export const modalTypes = {
  BOOKING_POLICY: 'BOOKING_POLICY',
  SPINNER: 'SPINNER',
  ERROR_DISPLAY: 'ERROR_DISPLAY',
}

export const viewTypes = {
  CHECKOUT: 'CHECKOUT',
  CONFIRMATION: 'CONFIRMATION',
  ERROR: 'ERROR',
}

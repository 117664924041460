import Radium from 'radium'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { selectLanguageStrings } from 'widget/paylink/selectors/languageSelectors'
import Button from '../../../../lib/Buttons/Button'
import { tryAgain } from '../actions/navigation'
import styles from '../assets/styles/confirmation'
import failStyles from '../assets/styles/confirmation'
import Banner from '../components/Banner'

class Error extends Component {
  // eslint-disable-line react/prefer-stateless-function
  render() {
    const {
      colorWidgetBackground,
      fontsColorPrimary,
      name,
      venueLogo,
      colorSummaryBar,
      colorButtonBackground,
      colorButtonText,
      fontsColorSummaryBar,
      mediaUrl,
      isWidgetWhitelabeled,
      textPaylinkPageContactInformation,
      textWidgetPoweredByLabel,
      textPaylinkPageErrorText,
      textPaylinkPageTryAgainText,
      textPaylinkPagePaymentErrorText,
    } = this.props

    const iconAdjust = {
      color: '#fff',
      fontSize: '20px',
      marginLeft: '1px',
      marginTop: '40px',
    }

    const errorMessage = widgetInit.errorCodes[widgetInit.errorDisplay] || textPaylinkPagePaymentErrorText

    return (
      <div style={[styles.successWraper, { backgroundColor: colorWidgetBackground }]}>
        <Banner name={name} logo={venueLogo} colorSummaryBar={colorSummaryBar} fontsColorSummaryBar={fontsColorSummaryBar} />

        <div style={styles.successBody}>
          <div
            style={[
              styles.bodyText,
              {
                width: '85%',
                margin: '80px auto 30px',
                color: fontsColorPrimary,
              },
            ]}
          >
            <div>
              <span className="fa-stack fa-lg" style={{ color: fontsColorPrimary, fontSize: '50px' }}>
                <i style={failStyles.fontIcon} className="fa fa-switch-circle fa-stack-1x" />
                <i style={[failStyles.fontIcon, iconAdjust]} className="fa fa-times fa-stack-2x" />
              </span>
            </div>
            <span style={[styles.largeConfirmText, { paddingTop: '0' }]}>{textPaylinkPageErrorText}</span>
            <span style={[failStyles.confirmation, { fontSize: '16px' }]}>
              {errorMessage}
              <br />
              <div
                dangerouslySetInnerHTML={{
                  __html: textPaylinkPageContactInformation,
                }}
              />
            </span>
            <Button
              innerElement={textPaylinkPageTryAgainText}
              onClick={() => {
                window.location = window.location.origin + window.location.pathname
              }}
              activeStyling
              style={[
                styles.tryAgainButton,
                {
                  backgroundColor: colorButtonBackground,
                  color: colorButtonText,
                },
              ]}
            />
          </div>
        </div>
        {isWidgetWhitelabeled !== 'true' && (
          <div style={styles.paylinkFooter}>
            <p>{textWidgetPoweredByLabel}</p>
            <p>
              <img style={{ height: '25px' }} src={`${mediaUrl}images/sevenrooms_logo.png`} />
            </p>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  const languageStrings = selectLanguageStrings(state)
  return {
    phone: state.venueInfo.phoneNumber,
    mediaUrl: state.widgetSettings.mediaUrl,
    // venue info
    name: state.venueInfo.name,
    venueLogo: state.venueInfo.venueLogo,
    // colors
    colorPrimary: state.widgetSettings.colorPrimary,
    colorWidgetBackground: state.widgetSettings.colorWidgetBackground,
    colorSummaryBar: state.venueInfo.venueColor,
    colorButtonBackground: state.venueInfo.buttonBgColor,
    colorButtonText: state.venueInfo.buttonTextColor,
    fontsColorPrimary: state.widgetSettings.fontsColorPrimary,
    fontsColorSummaryBar: state.venueInfo.venueTextColor,
    isWidgetWhitelabeled: state.widgetSettings.isWidgetWhitelabeled,

    textPaylinkPageContactInformation: languageStrings.textPaylinkPageContactInformation,
    textWidgetPoweredByLabel: languageStrings.textWidgetPoweredByLabel,
    textPaylinkPageErrorText: languageStrings.textPaylinkPageErrorText,
    textPaylinkPageTryAgainText: languageStrings.textPaylinkPageTryAgainText,
    textPaylinkPagePaymentErrorText: languageStrings.textPaylinkPagePaymentErrorText,
  }
}

const mapDispatchToProps = dispatch => ({
  tryAgain: () => {
    dispatch(tryAgain())
  },
})

Error = connect(mapStateToProps, mapDispatchToProps)(Radium(Error))

export default Error

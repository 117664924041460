import _ from 'lodash'
import Radium from 'radium'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import {
  postCheckoutFailure,
  postCheckoutPageReloadOnFailure,
  submitFreedompayPaymentRequest,
  fetchOrRefreshFreedompayDataForIframe,
  setFreedompayVisible,
} from '../actions/services'
import styles from '../assets/styles/checkout'
import { PAYMENT_CHANNELS, PaymentChannelMapping } from 'svr/lib/Payments/Constants'
import { Loader, Root } from '@sevenrooms/core/ui-kit/layout'

class CheckoutPaymentFreedompay extends Component {
  constructor(props) {
    super(props)
    this.success_3ds_validation = false
    this.failed_authentication = false
    this.bypassed_3ds = false

    this.paymentMethod = PAYMENT_CHANNELS.NEW_CREDIT_CARD
    this.channels = undefined
    this.iframeStyles = `
      button {
        border: none;
        background-color: ${this.props.colorPrimary};
        outline: 0 !important;
        box-shadow: none !important;
        cursor: pointer;
        white-space: nowrap;
        text-decoration: none !important;
        font-size: 14px;
        font-weight: 500;
        width: 100%;
        height: 38px;
        font-color: ${this.props.fontsColorButton};
      }
      button:hover {
        background-color: ${this.props.colorPrimary};
      }
      label {
        font-size: 14px;
        font-weight: 500;
        font-color: ${this.props.fontsColorPrimary};
      }
      input {
        width: 100%;
        font-size: 14px;
        font-weight: 400;
        border-color: ${this.props.colorPrimary};
        color: ${this.props.fontsColorPrimary};
        border-color: ${this.props.colorLines};
      }
      input::placeholder {
        color: ${this.props.fontsColorSecondary};
      }
      .fp-apple-pay-button {
        width: 280px;
        height: 38px;
      }
    `
  }

  componentDidMount() {
    window.addEventListener('message', this.handleFreedompayMessage)
    this.props.fetchOrRefreshFreedompayDataForIframe(this.props.venueId, this.paymentMethod, this.iframeStyles)
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleFreedompayMessage)
  }

  componentDidUpdate() {
    if (!this.channels) {
      return
    }
    for (const item of this.channels) {
      if (item === this.paymentMethod) {
        const methodBtn = document.querySelector(`button[data-test=${item}]`)
        if (methodBtn) {
          methodBtn.style.borderWidth = '2px'
          methodBtn.style.borderColor = this.props.colorPrimary
        }
      } else {
        const methodBtn = document.querySelector(`button[data-test=${item}]`)
        if (methodBtn) {
          methodBtn.style.borderWidth = '1px'
          methodBtn.style.borderColor = this.props.colorLines
        }
      }
    }
  }

  validOrigin(origin) {
    const url = new URL(document.getElementById('freedompay-src').src)

    if (origin === `https://${url.hostname}`) {
      return true
    } else if (this.isApplePay() && origin === window.origin) {
      return true
    }
    return false
  }

  handleFreedompayMessage = e => {
    if (!this.validOrigin(e.origin)) {
      return
    }
    const message = e.data
    const { data } = message
    const FP_ERROR = 1
    const FP_CHANGE_HEIGHT = 2
    const FP_SUBMITTED = 3
    const FP_THREEDS_VALIDATION = 16
    switch (message.type) {
      case FP_ERROR:
        this.handleErrors(data)
        break
      case FP_CHANGE_HEIGHT:
        this.setFrameHeight(data)
        break
      case FP_SUBMITTED:
        this.submitPayment(data)
        break
      case FP_THREEDS_VALIDATION:
        this.handleThreeDSValidation(data)
        break
      default:
        break
    }
  }

  handleErrors(data) {
    const errors = _.map(data.errors, error => error.message)
    this.props.postCheckoutFailure(errors.join('\n'))
    if (data.errors[0].messageCode === 'consumerAuthCanceled') {
      return
    }
    this.props.fetchOrRefreshFreedompayDataForIframe(this.props.venueId, this.paymentMethod, this.iframeStyles)
  }

  handleThreeDSValidation(data) {
    const failedECIFlagValues = ['00', '07']
    if (this.props.isBypass3dsEnabled) {
      if (data?.Payment?.ExtendedData?.Enrolled === 'B') {
        this.bypassed_3ds = true
      } else if (data.CmpiLookup?.Enrolled === 'B') {
        this.bypassed_3ds = true
      }

      if (this.bypassed_3ds) {
        return
      }
    }

    if (data?.Payment?.ExtendedData) {
      const extendedData = data.Payment.ExtendedData
      if (!extendedData.CAVV && failedECIFlagValues.includes(extendedData.ECIFlag)) {
        this.props.postCheckoutFailure('3DS validation has failed - Failed StepUp Authentication')
        this.failed_authentication = true
        return
      }
    } else if (data?.CmpiLookup && this.props.isFrictionlessHandlingEnabled) {
      const cmpiLookupData = data.CmpiLookup
      if (!cmpiLookupData.Cavv && failedECIFlagValues.includes(cmpiLookupData.EciFlag)) {
        this.props.postCheckoutFailure('3DS validation has failed - Failed Frictionless Authentication')
        this.failed_authentication = true
        return
      }
    }
    this.success_3ds_validation = true
  }

  isApplePay() {
    return this.paymentMethod === PAYMENT_CHANNELS.APPLE_PAY
  }

  setFrameHeight(data) {
    document.getElementById('sr-checkout-payment').getElementsByTagName('iframe')[0].height = data.height
  }

  submitPayment(freedompayData) {
    const bypassSuccessful = this.bypassed_3ds && this.props.isBypass3dsEnabled
    const verificationCompleted = this.success_3ds_validation || !this.props.isFrictionlessHandlingEnabled || this.isApplePay()
    if (verificationCompleted || bypassSuccessful) {
      this.props.submitFreedompayPaymentRequest(
        this.props.venueId,
        freedompayData,
        this.paymentMethod,
        this.props.textSpinnerMessage,
        this.props.commonPaymentErrors
      )
    } else if (!this.failed_authentication && this.props.isFrictionlessHandlingEnabled) {
      window.addEventListener('click', event => {
        window.location.reload()
      })
      this.props.setFreedompayVisible(false)
      this.props.postCheckoutPageReloadOnFailure(
        "Please use a card that supports 3D Secure authentication, as it's required for transactions at this venue. Try a different card to continue.",
        'Reload',
        () => window.location.reload()
      )
    }
  }

  googlePayAvailable() {
    return !!window.PaymentRequest
  }

  applePayAvailable() {
    return !!window.ApplePaySession
  }

  scanForChannels() {
    let options = [PAYMENT_CHANNELS.NEW_CREDIT_CARD]
    if (this.props.isAppleAndGooglePayEnabled) {
      if (this.googlePayAvailable()) {
        options = [PAYMENT_CHANNELS.GOOGLE_PAY, ...options]
      }
      if (this.applePayAvailable()) {
        options = [PAYMENT_CHANNELS.APPLE_PAY, ...options]
      }
    }
    return options
  }

  render() {
    const { topElementId, freedompay } = this.props
    if (!freedompay.initialData) {
      return null
    }
    this.channels = this.scanForChannels()
    return (
      <PaymentDiv id={topElementId}>
        <PaymentInterface>
          <CloseButton
            onClick={() => {
              this.props.setFreedompayVisible(false)
            }}
          >
            &times;
          </CloseButton>
          <Header>Payment</Header>
          {this.channels && this.channels.length > 1 ? (
            <>
              <Description>Which payment method would you like to proceed with?</Description>
              <PaymentMethodContainer>
                {this.channels.map(channel => (
                  <PaymentMethodButton
                    key={channel}
                    onClick={() => {
                      this.paymentMethod = channel
                      this.props.fetchOrRefreshFreedompayDataForIframe(this.props.venueId, this.paymentMethod, this.iframeStyles)
                    }}
                    borderColor={this.props.colorLines}
                    data-test={channel}
                  >
                    <img src={`${this.props.mediaUrl}images/widget/${PaymentChannelMapping[channel]?.icon}`} />
                    <ButtonLabel>{PaymentChannelMapping[channel]?.label}</ButtonLabel>
                  </PaymentMethodButton>
                ))}
              </PaymentMethodContainer>
            </>
          ) : null}
          {!freedompay?.isIframeInitialized ? (
            <LoaderDiv>
              <Root theme="gx">
                <Loader />
              </Root>
            </LoaderDiv>
          ) : (
            <PaymentIframe style={styles.infoForm} dangerouslySetInnerHTML={{ __html: freedompay.initialData.iframe }} />
          )}
        </PaymentInterface>
        <Cover />
      </PaymentDiv>
    )
  }
}

const PaymentDiv = styled.div`
  width: auto;
  height: auto;
  margin: 0 auto;
  border-radius: 8px;
  border-width: 1px;
  border-color: white;
  border-style: ${props => (!props.isValid && props.submitClicked ? 'solid' : 'hidden')};
`

const LoaderDiv = styled.div`
  padding-top: 32px;
`

const PaymentIframe = styled.div`
  z-index: 22;
  top: 24px;
  position: relative;
  display: flex;
  justify-content: center;
  background: white;
  border-radius: 0px !important;
  box-shadow: none !important;
  padding: 8px;
`

const Cover = styled.div`
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
`

const PaymentInterface = styled.div`
  position: fixed;
  top: 10vh;
  left: 50%;
  margin-left: -174px;
  border-radius: 12px;
  padding: 24px;
  background: white;
  z-index: 22;
  display: block;
  width: 330px;
`

const CloseButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px 10px;
  color: #999;
  cursor: pointer;
  z-index: 97;
  font-size: 25px;
`

const Description = styled.span`
  font-size: 14px;
  font-weight: 400;
  position: relative;
  top: 16px;
  display: flex;
  height: 38px;
  padding-top: 14px;
  padding-bottom: 42px;
`

const Header = styled.label`
  font-size: 18px;
  font-weight: 600;
  position: fixed;
`

const PaymentMethodButton = styled.button`
  flex-basis: 46%;
  flex-grow: 1;
  height: 42px;
  margin-bottom: 8px;
  border-radius: 6px;
  border-width: 1px;
  background-color: #fcfcfd;
  border-color: ${props => props.borderColor};
  display: flex;
  align-items: center;
  justify-content: center;
  border-style: solid;
`

const ButtonLabel = styled.label`
  font-size: 14px;
  font-weight: 500;
  margin-left: 4px;
`

const PaymentMethodContainer = styled.div`
  position: relative;
  top: 24px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  column-gap: 8px;
`

const mapStateToProps = state => ({
  isFrictionlessHandlingEnabled: state.venueInfo.isFreedompayHpcFrictionlessHandlingEnabled,
  isBypass3dsEnabled: state.venueInfo.isFreedompayBypass3DsEnabled,
  isAppleAndGooglePayEnabled: state.venueInfo.isFreedompayAppleAndGooglePayEnabled,
  venueInfo: state.venueInfo,
  venueId: state.venueInfo.id,
  freedompay: state.payment.freedompay,
  colorPrimary: state.widgetSettings.colorPrimary,
  colorLines: state.widgetSettings.colorLines,
  fontsColorPrimary: state.widgetSettings.fontsColorPrimary,
  fontsColorSecondary: state.widgetSettings.fontsColorSecondary,
  fontsColorButton: state.widgetSettings.fontsColorButton,
  mediaUrl: state.widgetSettings.mediaUrl,
})

const mapDispatchToProps = {
  submitFreedompayPaymentRequest,
  postCheckoutFailure,
  postCheckoutPageReloadOnFailure,
  fetchOrRefreshFreedompayDataForIframe,
  setFreedompayVisible,
}

export default connect(mapStateToProps, mapDispatchToProps)(Radium(CheckoutPaymentFreedompay))

import Radium from 'radium'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Checkout from './Checkout'
import Confirmation from './Confirmation'
import Error from './Error'
import ModalManager from './ModalManager'

class ViewManager extends Component {
  constructor(props) {
    super(props)

    this.viewMap = {
      CHECKOUT: Checkout,
      CONFIRMATION: Confirmation,
      ERROR: Error,
    }
  }

  render() {
    const View = this.viewMap[this.props.currentView]
    return (
      <div>
        <ModalManager />
        <View />
      </div>
    )
  }
}

const mapStateToProps = state => {
  const stage = state.ui.get('stage')
  const viewSequence = state.ui.get('viewSequence').toJS()
  return {
    currentView: viewSequence[stage],
  }
}

export default connect(mapStateToProps, null)(Radium(ViewManager))

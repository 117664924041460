import React, { Component } from 'react'
import { connect } from 'react-redux'
import { initialize } from '../actions/navigation'
import ViewManager from './ViewManager'

class App extends Component {
  componentDidMount() {
    this.props.initialize()
  }

  render() {
    return <ViewManager />
  }
}

const mapDispatchToProps = dispatch => ({
  initialize: () => {
    dispatch(initialize())
  },
})

App = connect(null, mapDispatchToProps)(App)

export default App

/* eslint import/no-extraneous-dependencies: 0, react/jsx-filename-extension: 0 */

import { StyleRoot } from 'radium'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { applyMiddleware, createStore, compose } from 'redux'
import thunk from 'redux-thunk'
import App from './containers/App'
import { rootReducer } from './reducers/combineReducers'
import preloadedState from './utils/preloadedState'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(rootReducer, preloadedState, composeEnhancers(applyMiddleware(thunk)))

render(
  <Provider store={store}>
    <StyleRoot>
      <App />
    </StyleRoot>
  </Provider>,
  document.getElementById('paylink-widget-app')
)

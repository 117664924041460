const styles = {
  switchWrapper: {
    display: 'inline-block',
    position: 'relative',
    height: '23px',
    marginTop: '-2px',
    width: '47px',
    cursor: 'pointer',
  },
  switchBackgroundIcon: {
    position: 'absolute',
    fontSize: '48px',
    top: '-13px',
  },
  switchCircle: {
    position: 'absolute',
    top: '-2px',
    width: '27px',
    height: '27px',
    backgroundColor: 'white',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '50%',
    boxShadow: '0px 0px 7px -1px rgba(0,0,0,0.75)',
  },
  circleRight: {
    right: '-1px',
  },
  circleLeft: {
    left: '-1px',
  },
}

export default styles

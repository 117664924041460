import {
  PAYER_AUTHENTICATION_SETUP_SERVICE_COMPLETE,
  PAYER_AUTHENTICATION_ENROLLMENT_COMPLETE,
  CLEAN_PAYMENT_INFORMATION,
  PayerAuthStep,
} from '../actions/cybersourceThreeDs'

const initialState = {
  payerAuthenticationSetupResult: undefined,
  payerAuthenticationEnrollmentResult: undefined,
  step: undefined,
}

const cybersourceThreeDsPaymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case PAYER_AUTHENTICATION_SETUP_SERVICE_COMPLETE:
      return {
        ...state,
        payerAuthenticationSetupResult: action.data,
        step: PayerAuthStep.SETUP,
      }
    case PAYER_AUTHENTICATION_ENROLLMENT_COMPLETE:
      return {
        ...state,
        payerAuthenticationEnrollmentResult: action.data,
        step: PayerAuthStep.ENROLLMENT,
      }
    case CLEAN_PAYMENT_INFORMATION:
      return initialState
    default:
      return state
  }
}

export default cybersourceThreeDsPaymentReducer

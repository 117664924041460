import Radium from 'radium'
import React from 'react'
import styles from '../assets/styles/modals'

const PolicyPopover = ({ policyHeader, policyDetails, dismissModal, headerColor, headerTextColor, textColor }) => (
  <div style={styles.modalWidth}>
    <div style={[styles.policyHeader, { backgroundColor: headerColor, color: headerTextColor }]}>
      <span style={styles.headerText}>{policyHeader}</span>
      <span style={styles.headerX} onClick={dismissModal}>
        ×
      </span>
    </div>
    <div style={[styles.policyBody, { color: textColor }]} dangerouslySetInnerHTML={{ __html: policyDetails }} />
  </div>
)

PolicyPopover.propTypes = {
  policyHeader: React.PropTypes.string.isRequired,
  policyDetails: React.PropTypes.string.isRequired,
  dismissModal: React.PropTypes.func.isRequired,
  headerColor: React.PropTypes.string,
  headerTextColor: React.PropTypes.string,
  textColor: React.PropTypes.string,
}

PolicyPopover.defaultProps = {
  headerColor: 'black',
  headerTextColor: 'grey',
  textColor: 'black',
}

export default Radium(PolicyPopover)

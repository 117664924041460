import {
  SET_FREEDOMPAY_INITIAL_DATA,
  SET_FREEDOMPAY_IFRAME_INITIALIZED,
  SET_FREEDOMPAY_VISIBLE,
  SET_NETWORK_REDUX_RESPONSE,
  SET_PAYMENT_PENDING_RESPONSE,
} from '../actions/ActionTypes'

const initialState = {
  freedompay: {
    initialData: null,
    visible: false,
    isIframeInitialized: false,
  },
  networkReduxResponse: null,
}

const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FREEDOMPAY_INITIAL_DATA:
      return {
        ...state,
        freedompay: {
          ...state.freedompay,
          initialData: action.data,
        },
      }
    case SET_FREEDOMPAY_VISIBLE:
      return {
        ...state,
        freedompay: {
          ...state.freedompay,
          visible: action.data,
        },
      }
    case SET_FREEDOMPAY_IFRAME_INITIALIZED:
      return {
        ...state,
        freedompay: {
          ...state.freedompay,
          isIframeInitialized: action.data,
        },
      }
    case SET_NETWORK_REDUX_RESPONSE:
      return {
        ...state,
        networkReduxResponse: action.networkReduxResponse,
      }
    case SET_PAYMENT_PENDING_RESPONSE:
      return {
        ...state,
        paymentPendingResponse: action.paymentPendingResponse,
      }
    default:
      return state
  }
}

export default paymentReducer

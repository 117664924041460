/* eslint react/prop-types: 0, no-shadow: 0 */
import _ from 'lodash'
import Radium from 'radium'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { selectLanguageStrings } from 'widget/paylink/selectors/languageSelectors'
import ErrorDisplay from '../../../../lib/Modal/ErrorDisplay'
import Modal from '../../../../lib/Modal/Modal'
import Spinner from '../../../../lib/Modal/Spinner'
import { changeFormField } from '../actions/forms'
import { dismissModal } from '../actions/navigation'
import PolicyPopover from '../components/PolicyPopover'
import { modalTypes } from '../utils/constantTypes'

class ModalManager extends Component {
  constructor(props) {
    super(props)

    this.modalSwitch = this.modalSwitch.bind(this)
  }

  modalSwitch(modalType) {
    const {
      dismissModal,
      eventWidgetPurchasePolicy,
      colorPrimary,
      fontsColorSecondary,
      mediaUrl,
      modalMessage,
      colorError,
      fontsColorButton,
      isDining,
      textWidgetCancellationPolicyHeader,
      textPaylinkPageCreditCardPolicy,
      okMessage,
      textPaylinkPageUnableToProcess,
      actionText,
      actionCallback,
    } = this.props
    const { BOOKING_POLICY, SPINNER, ERROR_DISPLAY } = modalTypes
    switch (modalType) {
      case BOOKING_POLICY:
        return (
          <PolicyPopover
            policyHeader={isDining ? textWidgetCancellationPolicyHeader : textPaylinkPageCreditCardPolicy}
            policyDetails={eventWidgetPurchasePolicy}
            dismissModal={dismissModal}
            headerColor={colorPrimary}
            headerTextColor={fontsColorButton}
            textColor={fontsColorSecondary}
          />
        )
      case SPINNER:
        return <Spinner mediaUrl={mediaUrl} label={modalMessage} />
      case ERROR_DISPLAY:
        return (
          <ErrorDisplay
            errorMessage={modalMessage}
            dismissModal={() => dismissModal() || actionCallback()}
            errorColor={colorError}
            okMessage={actionText || okMessage}
            textPaylinkPageUnableToProcess={textPaylinkPageUnableToProcess}
          />
        )
      default:
        return null
    }
  }

  render() {
    const { modalType, dismissModal, colorModalBackground } = this.props
    const dismissModalProp = modalType !== modalTypes.SPINNER ? dismissModal : () => {}
    if (!modalType) {
      return null
    }
    return <Modal body={this.modalSwitch(modalType)} dismissModal={dismissModalProp} backgroundColor={colorModalBackground} />
  }
}

const mapStateToProps = state => {
  const languageStrings = selectLanguageStrings(state)
  return {
    modalType: state.ui.get('displayModalType'),
    modalMessage: state.ui.get('modalMessage'),
    actionText: state.ui.get('actionText'),
    actionCallback: state.ui.get('actionCallback') || (() => null),
    isDining: state.venueInfo.isDining,
    eventWidgetPurchasePolicy: state.venueInfo.eventWidgetPurchasePolicy,
    mediaUrl: state.widgetSettings.mediaUrl,
    // colors
    colorPrimary: state.widgetSettings.colorPrimary,
    colorModalBackground: state.widgetSettings.colorModalBackground,
    colorError: state.widgetSettings.colorError,
    fontsColorSecondary: state.widgetSettings.fontsColorSecondary,
    fontsColorCheckoutActive: state.widgetSettings.fontsColorCheckoutActive,
    fontsColorButton: state.widgetSettings.fontsColorButton,
    textWidgetCancellationPolicyHeader: languageStrings.textWidgetCancellationPolicyHeader,
    textPaylinkPageCreditCardPolicy: languageStrings.textPaylinkPageCreditCardPolicy,
    textPaylinkPageUnableToProcess: languageStrings.textPaylinkPageUnableToProcess,
    okMessage: languageStrings.textcommonOk,
  }
}

const mapDispatchToProps = dispatch => ({
  dismissModal: () => {
    dispatch(dismissModal())
  },
  changeFormField: (field, changeTo) => {
    dispatch(changeFormField(field, changeTo))
  },
})

ModalManager = connect(mapStateToProps, mapDispatchToProps)(Radium(ModalManager))

export default ModalManager

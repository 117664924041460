const styles = {
  successWraper: {
    width: '100%',
    height: '100%',
    minHeight: '99.5vh',
  },
  successHeader: {
    width: '100%',
    minHeight: '132px',
  },
  smallConfirmText: {
    display: 'inline-block',
    margin: '22px auto 20px',
    width: '100%',
    fontSize: '16px',
    lineHeight: '1.2',
    letterSpacing: '1px',
    color: 'white',
    textAlign: 'center',
  },
  largeConfirmText: {
    display: 'inline-block',
    width: '100%',
    fontSize: '28px',
    textAlign: 'center',
    padding: '0 12px 20px',
  },
  successBody: {
    width: '100%',
    padding: '40px 0px 60px',
  },
  bodyText: {
    width: '100%',
    opacity: '0.7',
    fontSize: '14px',
    textAlign: 'center',
    marginBottom: '38px',
  },
  summaryDetails: {
    width: '100%',
    opacity: '0.7',
    fontSize: '14px',
    textAlign: 'center',
    marginBottom: '50px',
  },
  textLine: {
    display: 'inline-block',
    width: '100%',
    textAlign: 'center',
  },
  email: {
    display: 'inline-block',
    width: '100%',
    textAlign: 'center',
  },
  shareBlock: {
    width: '100%',
    height: '65px',
    textAlign: 'center',
    marginBottom: '50px',
  },
  followUsBlock: {
    clear: 'both',
    marginBottom: '10px',
  },
  followText: {
    display: 'inline-block',
    width: '100%',
    height: '19px',
    opacity: '0.7',
    fontSize: '14px',
    textAlign: 'center',
    marginBottom: '6px',
  },
  iconBox: {
    width: '100%',
    height: '50px',
    opacity: '0.5',
    fontSize: '28px',
    textAlign: 'center',
    marginTop: '13px',
  },
  socialIcon: {
    display: 'inline-block',
    height: '50px',
    width: '50px',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderRadius: '50%',
    position: 'relative',
    paddingTop: '9px',
    margin: '0 2.5%',
  },
  fontIcon: {
    // color: fontsColorPrimary,
  },
  paylinkFooter: {
    borderTop: '1px solid #eee',
    color: '#aaa',
    fontSize: '12px',
    padding: '20px 0 20px',
    textAlign: 'center',
  },
  tryAgainButton: {
    width: '200px',
    height: '50px',
    borderRadius: '4px',
    textAlign: 'center',
    margin: '20px 4%',
    padding: '15px 0',
    fontWeight: '400',
    fontSize: '14px',
    letterSpacing: '1px',
    color: 'white',
    cursor: 'pointer',
  },
}

export default styles

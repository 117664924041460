import Radium from 'radium'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { selectLanguageStrings } from 'widget/paylink/selectors/languageSelectors'
import styles from '../assets/styles/confirmation'
import Banner from '../components/Banner'

// eslint-disable-next-line react/prefer-stateless-function
class Confirmation extends Component {
  componentDidMount() {
    const { wasPayment, actualId, billingHistoryId } = this.props

    const message = {
      event: 'payment',
      type: 'paylink',
      status: 'SUCCESS',
      isPayment: wasPayment,
      paylinkId: billingHistoryId,
      reservationId: actualId,
      message: '',
    }

    window.parent.postMessage(message, '*')
  }

  render() {
    const {
      facebookLink,
      twitterLink,
      snapchatLink,
      instagramLink,
      colorWidgetBackground,
      colorPrimary,
      fontsColorPrimary,
      name,
      venueLogo,
      colorSummaryBar,
      fontsColorSummaryBar,
      mediaUrl,
      wasPayment,
      isWidgetWhitelabeled,
      textWidgetPoweredByLabel,
      textPaylinkPageContactInformation,
      textPaylinkPageSuccessHeader,
      textPaylinkPageSuccessConfirmationText,
      textPaylinkPageFollowUsLabel,
      textPaylinkPageCcSuccess,
    } = this.props

    return (
      <div style={[styles.successWraper, { backgroundColor: colorWidgetBackground }]}>
        <Banner name={name} logo={venueLogo} colorSummaryBar={colorSummaryBar} fontsColorSummaryBar={fontsColorSummaryBar} />

        <div style={styles.successBody}>
          <div
            style={[
              styles.bodyText,
              {
                width: '75%',
                margin: '100px auto 30px',
                color: fontsColorPrimary,
              },
            ]}
          >
            <div>
              <span className="fa-stack fa-lg" style={{ color: colorPrimary, fontSize: '30px' }}>
                <i style={styles.fontIcon} className="fa fa-check fa-stack-1x" />
                <i style={styles.fontIcon} className="fa fa-circle-thin fa-stack-2x" />
              </span>
            </div>
            <span style={[styles.largeConfirmText, { paddingTop: '30px' }]}>{textPaylinkPageSuccessHeader}</span>
            {wasPayment ? (
              <span
                style={styles.confirmation}
                dangerouslySetInnerHTML={{
                  __html: textPaylinkPageSuccessConfirmationText,
                }}
              />
            ) : (
              <span style={styles.confirmation}>{textPaylinkPageCcSuccess}</span>
            )}
            <span
              style={styles.confirmation}
              dangerouslySetInnerHTML={{
                __html: textPaylinkPageContactInformation,
              }}
            />
          </div>
          <div style={[styles.followUs, { color: fontsColorPrimary }]}>
            {facebookLink || instagramLink || twitterLink || instagramLink ? (
              <span style={styles.followText}>{textPaylinkPageFollowUsLabel}</span>
            ) : null}
            <div style={[styles.iconBox, { color: fontsColorPrimary }]}>
              {facebookLink ? (
                <a target="_blank" rel="noopener noreferrer" href={facebookLink}>
                  <div style={[styles.socialIcon, { borderColor: fontsColorPrimary }]}>
                    <i style={styles.fontIcon} className="fa fa-facebook" />
                  </div>
                </a>
              ) : null}
              {instagramLink ? (
                <a target="_blank" rel="noopener noreferrer" href={instagramLink}>
                  <div style={[styles.socialIcon, { borderColor: fontsColorPrimary }]}>
                    <i style={styles.fontIcon} className="fa fa-social-instagram" />
                  </div>
                </a>
              ) : null}
              {twitterLink ? (
                <a target="_blank" rel="noopener noreferrer" href={twitterLink}>
                  <div style={[styles.socialIcon, { borderColor: fontsColorPrimary }]}>
                    <i style={styles.fontIcon} className="fa fa-twitter" />
                  </div>
                </a>
              ) : null}
              {snapchatLink ? (
                <a target="_blank" rel="noopener noreferrer" href={snapchatLink}>
                  <div style={[styles.socialIcon, { borderColor: fontsColorPrimary }]}>
                    <i style={styles.fontIcon} className="fa fa-social-snapchat" />
                  </div>
                </a>
              ) : null}
            </div>
          </div>
        </div>
        {isWidgetWhitelabeled !== 'true' && (
          <div style={styles.paylinkFooter}>
            <p>{textWidgetPoweredByLabel}</p>
            <p>
              <img style={{ height: '25px' }} src={`${mediaUrl}images/sevenrooms_logo.png`} />
            </p>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  const languageStrings = selectLanguageStrings(state)
  const email = state.formFields.get('email') || state.actualInfo.email
  return {
    actualId: state.actualInfo.id,
    billingHistoryId: state.billingInfo.billing_history_id,
    mediaUrl: state.widgetSettings.mediaUrl,
    wasPayment: !!state.billingInfo.amount,
    // venue info
    name: state.venueInfo.name,
    facebookLink: state.venueInfo.facebookLink,
    twitterLink: state.venueInfo.twitterLink,
    snapchatLink: state.venueInfo.snapchatLink,
    instagramLink: state.venueInfo.instagramLink,
    venueLogo: state.venueInfo.venueLogo,
    // colors
    colorPrimary: state.widgetSettings.colorPrimary,
    colorWidgetBackground: state.widgetSettings.colorWidgetBackground,
    colorSummaryBar: state.venueInfo.venueColor,
    fontsColorPrimary: state.widgetSettings.fontsColorPrimary,
    fontsColorSummaryBar: state.venueInfo.venueTextColor,
    isWidgetWhitelabeled: state.widgetSettings.isWidgetWhitelabeled,

    textWidgetPoweredByLabel: languageStrings.textWidgetPoweredByLabel,
    textPaylinkPageContactInformation: state.venueInfo.phoneNumber
      ? `<br /><br />${languageStrings.textPaylinkPageContactInformation.replace('<venue_phone_number>', state.venueInfo.phoneNumber)}`
      : '',
    textPaylinkPageSuccessHeader: languageStrings.textPaylinkPageSuccessHeader,
    textPaylinkPageSuccessConfirmationText: languageStrings.textPaylinkPageSuccessConfirmationText.replace('<guest_email>', email),
    textPaylinkPageFollowUsLabel: languageStrings.textPaylinkPageFollowUsLabel,
    textPaylinkPageCcSuccess: languageStrings.textPaylinkPageCcSuccess,
  }
}

Confirmation = connect(mapStateToProps)(Radium(Confirmation))

export default Confirmation
